import React,{useEffect} from 'react';
import { Mixpanel } from '@/utils/mixpanel';
import SipjoyAppContext from '@/src/contexts/SipjoyAppContext';
import SitePage from '@/src/components/SitePage';

import { getSitesPagesToLoadFromService } from "@/src/services/sipjoyService";
import { generateSiteProperties, getPageAppContextProps } from '@/src/contexts/SiteContext';
import NotFound from "@/components/views/supportingPages/NotFound"

const customPage = ({siteContext, page ,slug}) => {
  if (!siteContext) {
    console.error('Could not load site properties')
    return <NotFound/>
  }
  if (!page) {
    console.error(`${slug} page is not defined for this site`)
    return <NotFound/>
  }
  if (page?.theme) {
    siteContext.site.theme = page.theme
  }
  
  return (
    <SipjoyAppContext siteContext={siteContext} {...getPageAppContextProps(page)}>
      <SitePage content={page}/>
    </SipjoyAppContext>
  )
};

export default customPage;



export async function getStaticProps({ params }) {
  let { siteKey , slug} = params;
  
  let page;
  let siteContext= siteKey && await generateSiteProperties(siteKey)

  if (siteContext) {
    const pages = siteContext.site.custom_site_pages;
    page = pages.find(p=> p.slug === slug)
    page = page && JSON.parse(JSON.stringify(page.page))
    siteContext = JSON.parse(JSON.stringify(siteContext))
  } else {
    siteContext = undefined;
  }
  return {
    revalidate: 60, // 1 minute
    props: { siteContext, slug , page }, 
  }
}

export async function getStaticPaths() {
  let pagesToLoad = [];
  try {

    const allPages = await getSitesPagesToLoadFromService()
    pagesToLoad =
      allPages && allPages.map((p) => ({ siteKey: p.siteKey, slug: p.slug }))
  } catch (err) {
    console.error('error while building static page', err)
  }
    
  return {
    paths: pagesToLoad.map(p => ({params: p})),
    fallback: true
  };
}